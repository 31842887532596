<script>
    import {getAllProfiles} from '@/routes/api/views/flujo';
    import {required} from "@/commons/utils/Rules";
    import PzBaseForm from "@/commons/forms/PzBaseForm";
    export default {
        name: 'PzBaseProductosAll',
        mixins: [PzBaseForm],
        provide: function () {
            return {
                myForm: this
            }
        },
        data() {
            return {
                reference: 'formularioProducts',
                type: '',
                productos: [],
                formularioProducts: {
                    selected: null,
                },
                rules_formularioProducts: {
                    selected: [required('')]
                }
            }
        },
        created() {
            this.fechtData();
        },
        methods: {
            fechtData() {

                this.$store.dispatch('loader/up',{trigger:this.$options.name});
                this.type = this.$route.params.type;
                getAllProfiles({
                    type: this.type
                }).then((res) => {
                    this.productos = res.data.productos;
                }).catch((error) => {
                    this.$store.dispatch('app/catchErrors', {_this: this, error: error})
                }).finally(() => {

                    this.$store.dispatch('loader/down',{trigger:this.$options.name});
                });
            }
        },
    }
</script>


