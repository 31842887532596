<template>
  <el-row class="padding">
    <el-col
      :xl="{span: 12, offset:6 }"
      :lg="{span: 12 ,offset:6}"
      :md="{span: 12 ,offset:6}"
      :sm="{span: 18 ,offset:3}"
      :xs="{span: 18 ,offset:3}"
    >
      <el-card class="center-block box-green-dark">
        <h2 class="text-center">
          <span class="x-large">
            {{ $t('forms_flujo.oferta_up_descuentos_default.label_1') }}
            {{ myForm.best.monto|currency(myForm.producto.moneda_prestamo.symbol, 0) }} </span>
        </h2>
        <h2 class="text-center">
          {{ $t('forms_flujo.oferta_up_descuentos_default.label_2') }}
          <span class="x-large"> {{ myForm.best.tea }}%</span>
        </h2>
      </el-card>
    </el-col>
    <el-row class="center-block text-center">
      <el-col :span="24">
        <el-button
          round
          class="green-ligth"
          @click="myForm.setOferta()"
        >
          {{ $t('forms_flujo.oferta_up_descuentos_default.btn_accept') }}
        </el-button>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>

    export default {
        name: 'PzOfertaUpDescuentosUy',
        inject: ['myForm'],
        mounted() {
        }
    }
</script>

